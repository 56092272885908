.company-details {
    margin-bottom: 80px;
    margin-right: 30px;
    margin-left: 30px;
    padding: 1rem;
}

.company-details-h3 {
    font-size: 1.75rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.company-details-h4 {
    font-size: 1.5rem;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 50px;
}

.company-details-h5 {
    font-size: 1.25rem;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 40px;
}

.company-details-p {
    margin-top: 0;
    margin-bottom: 35px;
}

.company-details-h5-pointer {
    font-size: 1.25rem;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    cursor: pointer;
}

.company-details-ul {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    list-style: none;
    width: 60%;
    margin-bottom: 80px;
}

.company-details-li {
    border: 1px solid rgba(0,0,0,.125);
    padding: 0.5rem 1rem;
}