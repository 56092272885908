.companies-page-main {
    margin: 0 10px;
}

.companies-page-suggest-value {
    height: 162px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 0.125rem;
    background-color: #fff;
    margin: 0 12px;
    padding: 0.5rem 2rem 4rem;
}

.companies-page-suggest-value>h4 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.companies-page-suggest-value>p {
    color: #6c757d;
    font-size: 16px;
    font-weight: 300;
}

.companies-page-suggest-value>div {
    display: flex;
    align-items: center;
    gap: 15px;
}

.companies-page-suggest-value input {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #000;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 50%;
}

.companies-page-suggest-value button {
    color: #fff;
    background-color: #212529;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    user-select: none;
    border: 1px solid #212529;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.companies-page-content {
    margin: 12px;
}

.companies-image-bg {
    background-color: black;
    padding: 0 120px 40px;
    position: absolute;
    top: 178px;
    left: 0;
    right: 0;
    height: 900px;
}

.companies-coffee-image {
    border-radius: 15px;
    width: 1180px;
    height: 600px;
    object-fit: cover;
    margin: auto;
    display: block;
}

.companies-relative-container {
    position: relative;
    color: white;
    text-align: center;
    min-height: 600px;
}

.companies-h4 {
    font-size: 4rem;
    margin: 86px 0 25px;
}

.companies-p {
    font-size: 2rem;
    font-weight: 300;
}

.companies-share-company {
    background-color: lightgray;
    padding: 120px;
    width: 940px;
    margin: 170px auto 50px;
    border-radius: 15px;
    color: black;
    display: flex;
    justify-content: space-around;
}

.companies-share-company-span {
    font-size: 1.2em;
    margin: 20px;
    display: block;
}

.companies-share-company-h6 {
    font-size: 2.5em;
    margin: 20px;
}

a.companies-share-company-btn {
    border-radius: 5px;
    color: white;
    background-color: black;
    width: 115px;
    display: block;
    margin: auto;
    height: 47px;
    line-height: 47px;
    text-decoration: none;
}

.companies-share-company-gray-squere {
    width: 300px;
    height: 300px;
    background-color: #aaa6a6;
    border-radius: 5px;
}

.companies-pagination-container {
    display: flex;
    justify-content: center;
    gap: 50px;
}

.companies-pagination-container>button {
    width: 130px;
    height: 60px;
    background-color: #4f5258;
    color: white;
    font-size: 1.3em;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.companies-list-wrapper {
    margin: 45px auto;
    max-width: 1180px;
}

.companies-list-h5 {
    margin: 0 10px 30px;
    font-size: 1.8em;
}

.companies-list {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    list-style: none;
    padding: 0;
}

.company-card {
    font-size: 14px;
    border: 1px solid #e4dfdf;
    width: 22%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.company-card-header {
    background-color: hsla(0, 0%, 90.6%, .644);
    height: 107px;
    font-size: 18px;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, .125);
}

.company-card-header-flex {
    display: flex;
    align-items: center;
}

.company-link {
    display: flex;
    align-items: baseline;
    padding: 0 12px;
    font-size: 1rem;
}

.company-title {
    color: #00f;
    text-decoration: underline;
    font-size: 1rem;
    cursor: pointer;
    margin: 0;
}

.company-field {
    padding: 0 30px 40px;
}

.company-region {
    color: #000;
    text-decoration: none;
    font-size: 15px;
}

.company-card-domain {
    font-size: 1rem;
    margin-right: 50px;
}

.company-card-details {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex-grow: 1;
}

.company-card-address-details {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.company-card-other-info {
    padding: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.25rem;
}

.company-card-other-info>li {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: .75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    margin-right: 5px;
}

.company-card-filtering-level {
    background-color: #17a2b8;
}

.company-card-job-declaration {
    background-color: #6c757d;
}

.company-card-splitting-level {
    background-color: #ffc107;
}

.company-card-footer {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125);
}