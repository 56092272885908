body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.app-header {
  color: white;
  background-color: black;
  padding: 8px 16px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.app-header-img-container {
  flex: 0 0 auto;
  width: 8.33%;
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.app-header-img {
  border-radius: 5px;
}

.app-header-h1 {
  width: 33.33%;
  flex: 1 0 auto;
  font-size: 1.75rem;
  color: #0d6efd;
  text-decoration: underline;
}

.app-nav {
  font-size: 18px;
  display: flex;
  gap: 20px;
  flex: 0 0 auto;
  align-items: center;
  margin-left: 30px;
}