.company-tips {
    margin-bottom: 80px;
    margin-right: 30px;
    margin-left: 30px;
    padding: 1rem;
}

.company-tips-h4 {
    font-size: 1.5rem;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 50px;
}

.company-tips-h5 {
    font-size: 1.25rem;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 40px;
}