.search-form-tabs-list {
    display: flex;
    justify-content: center;
    gap: 30px;
    list-style: none;
    font-size: 1.5rem;
    margin-top: 50px;
    cursor: pointer;
}

.search-form-tabs-list>.active:after {
    content: "";
    display: block;
    border-bottom: 2px solid white;
    width: 30%;
    margin: 10px auto;
}

.search-form-form-container {
    display: flex;
    justify-content: center;
    gap: 24px;
    background-color: white;
    border-radius: 15px;
}

.search-form-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3px;
    margin: 15px 0;
    width: 17%;
}

.search-form-field>b {
    color: black;
    margin-bottom: 0.5rem;
}

.search-form-field-select {
    width: 100%;
}

.search-form-select__control {
    display: block;
    width: 90%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.search-form-field>input:focus {
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.search-form-field>input:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

.search-form-field-checkbox {
    flex-direction: row;
    align-items: center;
}

.search-form-splitter {
    border-left: 3px solid #f0f0f0;
}

.search-form-buttons-container {
    display: inline-flex;
    align-items: center;
}

.search-form-button {
    width: 45px;
    height: 31px;
    background-color: #1c1f23;
    color: white;
    font-size: 0.875rem;
    border: 1px solid #1c1f23;
    cursor: pointer;
}

.search-form-button:last-of-type {
    background-color: #fff;
    color: #1c1f23;
}

.search-form-advanced-search {
    cursor: pointer;
    background-color: #fff;
    border: none;
    color: #0d6efd;
    text-decoration: underline;
    line-height: 1.5;
    font-weight: 700;
    font-size: 1rem;
}

.advanced-search-h3 {
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
}

.advanced-search-flex-box {
    padding: 1rem;
    display: flex;
    gap: 30px;
}

.advanced-search-buttons-container {
    border-top: 1px solid #dee2e6;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.advanced-search-buttons-container>button {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    margin: 0.25rem;
    border: 1px solid transparent;
}

.advanced-search-buttons-container>button:first-of-type {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.advanced-search-buttons-container>button:last-of-type {
    color: #fff;
    background-color: #212529;
    border-color: #212529;
}