.company-page-main {
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    min-height: 95%;
    margin: 0 12px;
}

.company-page-header-section {
    height: 165px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 0.125rem;
    background-color: #fff;
    padding: 4rem 2rem;
    margin-bottom: 30px;
}

.company-page-header-flex {
    display: flex;
    gap: 15px;
    margin-bottom: 40px;
}

.company-page-header-h1 {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.company-page-header-h3 {
    font-size: 1.75rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.company-page-header-tabs {
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
    margin-bottom: 0;
    list-style: none;
    font-size: 20px;
    list-style: none;
}

.company-page-header-tabs > li {
    border-left: 2px solid #8f8c8c;
    padding: 0 30px;
    cursor: pointer;
}

.company-page-header-tabs > li:last-of-type {
    border-left: none;
}

.company-page-header-tabs > li.active {
    font-weight: 700;
}